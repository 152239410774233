import React from 'react'
import { Button } from 'antd'
import Layout from '../components/layout'
import { NewPageLink } from '../utils/utils'

const CVButton = () => (
  <div style={{ textAlign: 'center' }}>
    <a download href='/shervin-khastoo-resume.pdf'><Button type='primary' size='large'>Download My Full CV/Resume</Button></a>
  </div>
)

const ResumePage = () => (
  <Layout>
    <h1>Resume</h1>
    <p>To download my latest resume please click on the button below.</p>
    <br />
    <br />
    <CVButton />
  </Layout>
)

export default ResumePage
